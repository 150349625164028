import { useValue, useModel, useWidget, Widget, core, ViewModel } from "@essenza/react";
import { Select } from 'antd';
import { useEffect, useMemo } from "react";
import { GoogleGroupsModel } from "../data/googleGroupsModel";
import { GoogleMemberModel } from "../data/googleMemberModel";

export function GroupContent({ email, member }) {
    const vm = useWidget(GroupcontentVM);
    const groups = useValue("groups");

    vm.email = email;
    vm.member = member;

    const [model, data] = useModel(GoogleGroupsModel);

    /*vm.selected = useMemo(() => {
        let values = [];
        data && data.forEach(group => {
            group.label = group.name;
            group.value = group.email;
            values.push(group.value);
        });
        return values;
    }, [data])*/

    vm.selected = [];
    data && data.forEach(group => {
        group.label = group.name;
        group.value = group.email;
        vm.selected.push(group.value);
    });


    console.log("GroupContent", vm.selected, data);
    useEffect(() => {
        model.of(email);
    }, [model, email])

    return (
        <Widget>
            <Select labelInValue={true} onSelect={value => vm.emit("GROUP_ADD", value)} options={groups} value={vm.selected} fieldNames={{ label: "name", value: "email" }} onDeselect={value => vm.emit("GROUP_REMOVE", value)} mode="multiple" placeholder="Seleziona" className="w-[150px] bk:w-[250px] rounded-xxs border-medium-grey hover:border-blue focus:border-blue shadow-none text-copy text-left" />
        </Widget>
    )
};

export function GroupcontentVM() {
    ViewModel.call(this);
}

core.prototypeOf(ViewModel, GroupcontentVM, {
    intent: {
        GROUP_REMOVE: function ({ data }) {
            const remove = this.selected.length === 1;
            const model = this.$group;
            model.unsubscribe(this.email, data.value, remove).then(() => {
                const index = model.source.findIndex(g => g.email === data.value);
                model.source.splice(index, 1);
                if (remove)
                    this.member.remove();
                else
                    this.update();
            });
        },

        GROUP_ADD: function ({ data }) {
            const model = this.$group;
            model.subscribe(this.email, data.value).then(() => {
                data.name = data.label;
                data.email = data.value;
                model.source.push(data);
                this.update();
            });
        },
    }
});