import { useVista, Vista, ViewModel, core, useModel, UserModel, RULES } from "@essenza/react";
import { Login } from "../../widget/profile/login";
import { Logo } from "../../layout/logo";
import LoginScreen from '../../assets/img/login.jpg';
import * as yup from 'yup';

export function LoginVista() {
    const vm = useVista(LoginVVM);
    const [user] = useModel(UserModel);

    return (
        <Vista>
            <div className='w100 h100 flex flex-col items-center justify-center p-[32px] bk:p-[48px]'>
                <Logo className="w-[120px] bk:w-[200px] mb-[48px] bk:mb-[64px]"></Logo>
                <h1 className="font-medium mb-[16px] bk:mb-[32px] text-center">Welcome! Effettua il login per iniziare ad utilizzare GT Database.</h1>
                <Login user={user.newInstance()} rules={vm.rules} />
            </div>
        </Vista >
    )
}

export function LoginVVM() {
    ViewModel.call(this);
}

core.prototypeOf(ViewModel, LoginVVM, {
    get rules() {
        return yup.object({
            email: RULES.email(yup),
            password: RULES.password(yup),
        })
    },
    intent: {

    }
});