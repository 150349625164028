import { useWidget, Widget, core, ViewModel } from "@essenza/react";
//import { HomeVM } from "./homevm";

export function Insert() {
    const vm = useWidget(InsertVM);
    return (
        <Widget>
            <div className="w-full flex pt-32px px-[48px]">
                <div className="w-[1268px] flex flex-col">
                    <div className="w-2/3 bg-white p-32px">
                        <h1 className="text-copy"><a onClick={e => vm.emit("GO_BACK")}>&lt;</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Inserisci dei nuovi utenti</h1>
                        <div className="flex flex-row flex-nowrap">
                            <div>
                                <div className="w-1/2">
                                    <h2>Inserimento singolo</h2>
                                    <p>Inserisci manualmente un singolo utente</p>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <h2>Inserimento multiplo</h2>
                                    <p>Carica un .CSV con un elenco di utenti</p>
                                </div>
                                <div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Widget>
    )
}

export function InsertVM() {
    ViewModel.call(this);
}

core.prototypeOf(ViewModel, InsertVM, {
    intent: { //swipe or override
        GOTO_ADMIN: ({ context }) => {
            context.navigate("/");
        },
        GO_BACK: ({ context }) => {
            context.navigate("home");
        },
    }
});