import { useWidget, Widget, core, ViewModel, useBreakPoint, useApp } from "@essenza/react";
import { Button, Table, Input, Popover } from 'antd';
import { ReactComponent as AddUser } from "../assets/icon/addUser.svg";
import { ReactComponent as View } from "../assets/icon/view.svg";
import { GroupContent } from "../component/groupContent";
import { ReactComponent as AllUsers } from "../assets/icon/allUsers.svg";
import { ReactComponent as SubscribeIcon } from "../assets/icon/subscribeIcon.svg";
import { ReactComponent as UnsubscribeIcon } from "../assets/icon/unsubscribeIcon.svg";
import { ReactComponent as Logout } from '../assets/icon/logout.svg';

const { Search } = Input;
export function Home({ source }) {
    const vm = useWidget(HomeVM);
    const breakpoint = useBreakPoint('md');
    const app = useApp();
    return (
        <Widget>
            <div className="w-full flex pt-32px px-[32px] bk:px-[48px]">
                <div className="w-full bk:w-[1268px] flex flex-col gap-y-4 bk:gap-y-8 text-copy">
                    {breakpoint.md.active
                        ?
                        <>
                            <div className="flex flex-row items-center">
                                <div className="w-1/2 flex justify-start">
                                    <Search placeholder="Cerca utente" onSearch={value => vm.emit("SEARCH", value)} className="w-[350px]" />
                                </div>
                                <div className="w-1/2 flex justify-end">
                                    <Button onClick={() => vm.emit("ADD_MEMBER")} className="bg-blue hover:bg-copy text-white rounded-xs px-32px py-16px border-0 shadow-none flex flex-row gap-x-[16px] items-center">Aggiungi utente<AddUser className="fill-white" /></Button>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="flex flex-col justify-center">
                                <Search placeholder="Cerca utente" onSearch={value => vm.emit("SEARCH", value)} className="w-auto bk:w-[350px]" />
                            </div>
                        </>
                    }
                    <div>
                        <h1 className="p-[16px] bk:px-[24px] bk:py-[24px] bg-white rounded-t">Elenco Utenti</h1>
                        {/* {data ? <Table rowKey="id" columns={Columns(vm)} dataSource={data}></Table> : "Nessun utente presente"} */}
                        <Table rowKey="id" columns={Columns(vm)} dataSource={source} className="custom-table">

                        </Table>
                    </div>
                    {breakpoint.md.active
                        ?
                        <>
                        </>
                        :
                        <>
                            <div className="flex flex-row justify-center gap-x-[8px] bg-grey bottom-[10px] rounded p-[24px] custom-home-footer">
                                <Button onClick={() => app.navigate("home")} className="p-[10px] bg-trasparent hover:bg-blue active:bg-blue text-white rounded-xs border-0 shadow-none fill-blue hover:fill-white active:fill-white">{<AllUsers className="h-[25px] w-auto" />}</Button>
                                <Button onClick={() => vm.emit("ADD_MEMBER")} className="p-[10px] bg-trasparent hover:bg-blue active:bg-blue text-white rounded-xs border-0 shadow-none fill-blue hover:fill-white active:fill-white"><AddUser className="h-[25px] w-auto" /></Button>
                                <Button onClick={() => app.navigate("unsubscribe")} className="p-[10px] bg-transparent hover:bg-transparent text-copy hover:text-copy border-0 shadow-none sidebarButton fill-copy hover:fill-blue">{<SubscribeIcon className="h-[25px] w-auto" />}</Button>
                                <Button onClick={() => window.location.replace(window.location.origin)} className="p-[10px] bg-transparent hover:bg-transparent text-blue hover:text-copy border-0 shadow-none sidebarButton underline fill-copy hover:fill-blue">{<Logout className="h-[25px] w-auto" />}</Button>
                            </div>
                        </>
                    }
                </div>
            </div>
        </Widget>
    )
}

export function HomeVM() {
    ViewModel.call(this);
}

function Columns(vm) {
    return [
        {
            title: "Email",
            dataIndex: "email",
            key: "id",
            width: "60%",
        },
        {
            title: "Gruppi",
            dataIndex: "mailinglist",
            key: "id",
            width: "15%",
            render: (text, record) => {
                return (
                    <Popover content={<GroupContent email={record.gid} member={record} />} trigger="click" placement="rightTop">
                        <View className="cursor-pointer" />
                    </Popover>
                )
            },
        },
        {
            title: "Azioni",
            key: "id",
            width: "25%",
            render: (text, record) => {
                return (<Button className='bg-blue hover:bg-copy text-white rounded-xs px-8px py-4px border-0 shadow-none custom-responsive-button' onClick={() => vm.emit("DELETE", record)}>Elimina</Button>)
            },
        },
    ]
}

core.prototypeOf(ViewModel, HomeVM, {
    intent: {
        ADD_MEMBER: function ({ context }) {
            context.navigate("subscribe");
        },
    }
});