import { DataModel, core } from "@essenza/react";

export function GoogleMemberModel() {
    DataModel.call(this);
}

core.prototypeOf(DataModel, GoogleMemberModel, {
    etype: "member",

    //Tutti i membri nella console
    all() {
        return this.ExecuteQuery("members_all").then(result => {
            console.log("Tutti i membri chiamati con successo", result);
        });
    },

    search(query) {
        return this.ExecuteQuery("member_search", { query }).then(result => {
            console.log("Search membri chiamato con successo", result);
        });
    },

    //Mostra l'elenco di tutti i membri di un gruppo. Groupkey è l'email del gruppo.
    of(groupkey) {
        return this.ExecuteQuery("member_of", { groupkey: groupkey }).then(result => {
            console.log("Chiamata API con successo!", result);
        });
    },

    subscribe: function (email, groups) {
        return this.ExecuteApi("subscribe", { email: email, groups }).then(result => {
            console.log("Viva la fica!", result);
        });
    },

    unsubscribe: function (email) {
        return this.ExecuteApi("unsubscribe", { email }).then(result => {
            console.log("Viva la fica!", result);
        });
    },

    unsubscribeByEmail: function (email) {
        return this.ExecuteApi("unsubscribe_email", { email }).then(result => {
            console.log("Viva la fica!", result);
        });
    },

});