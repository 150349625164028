import { Link, bool, small, string, decimal, double, float, int, long, date, money, char } from "@essenza/react";

export function ConfigureApp(app) {
    app.setBaseUrl("https://database.gallitorrini.com/");
    //app.setBaseUrl("https://localhost:44370/");

    app.configureType({
        users: {
            fields: { id: int, name: string, surname: string, email: string, password: string, irole: int, username: string, nusername: string, nemail: string, emailvalid: bool, phone: string, phonevalid: bool, twofactor: bool, idplatform: int, businessname: string, locked: bool, dlog: date, dlast: date, cf: string, vatnumber: string, street: string, num: string, locality: string, code: string, city: string, url: string, complete: bool },
        },

        member: {
            fields: {
                deliverySettings: string,
                email: string,
                eTag: string,
                id: int,
                kind: string,
                role: string,
                status: string,
                type: string,
                mailinglist: string,
            }
        },

        group: {
            fields: {
                adminCreated: bool,
                aliases: string,
                description: string,
                directMembersCount: int,
                email: string,
                eTag: string,
                id: string,
                kind: string,
                name: string,

            }
        },
    });
}