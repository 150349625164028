import { useVista, Vista, useModel, ViewModel, core } from "@essenza/react";

import { Home, HomeVM } from "../widget/home";
import { useEffect } from "react";
import { GoogleMemberModel } from "../data/googleMemberModel";
import { notification } from "antd";

export function HomeVista() {
    useVista(HomeVVM);
    const [member, data] = useModel(GoogleMemberModel);

    useEffect(() => {
        member.all();
    }, [member]);

    return (
        <Vista>
            <Home source={data} />
        </Vista>
    )
}

export function HomeVVM(){
    ViewModel.call(this);
    this.home = this.bind(HomeVM); 
    this.home.listen("SEARCH", this);
    this.home.listen("DELETE", this);
}

core.prototypeOf(ViewModel, HomeVVM, {
    intent: { //swipe or override
        SEARCH: function({data}){
            this.$member.search(data);
        },
        
        DELETE({ data }){
            this.$member.unsubscribe(data.gid).then(()=>{
                notification.success({description: "Utente eliminato con successo."});
                data.remove();
            });
        },
    }
});