import { useVista, Vista } from "@essenza/react";

import { Insert } from "../widget/insert";

export function InsertVista() {
    const vm = useVista();

    return (
        <Vista>
            <Insert />
        </Vista>
    )
}