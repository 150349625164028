import { useWidget, Widget, core, ViewModel } from "@essenza/react";
import { Button } from "antd";
import { Logo } from "../layout/logo";
import { LoadingAtomicIcon } from "../layout/loadingAtomIcon";


export function Welcome() {
    const vm = useWidget(WelcomeVM);
    return (
        <Widget>
            <div className="w-full flex flex-col items-center p-[56px]">
                <Logo className="w-[150px] mb-[72px]"></Logo>
                <LoadingAtomicIcon></LoadingAtomicIcon>
            </div>
            {/* Da COMMENTARE il div sottostante una volta che l'app è online */}
            {/* <div className="w-full">
                <div className="text-center">WELCOME</div>
                <Button className="mx-auto block" onClick={e => vm.emit("GO_HOME")}>GO HOME</Button>
                <Button className="mx-auto block" onClick={e => vm.emit("GO_INSERT")}>GO INSERT</Button>
                <Button className="mx-auto block" onClick={e => vm.emit("GO_SUBSCRIBE")}>GO SUBSCRIBE</Button>
                <Button className="mx-auto block" onClick={e => vm.emit("GO_UNSUBSCRIBE")}>GO UNSUBSCRIBE</Button> */}
                {/* <Button className="mx-auto block" onClick={e => vm.emit("GO_ADMIN")}>GO ADMIN</Button>
                <Button className="mx-auto block" onClick={e => vm.emit("GO_INVITEIN")}>GO INVITEIN</Button> */}
            {/* </div> */}
        </Widget>
    )
}

export function WelcomeVM() {
    ViewModel.call(this);
}

core.prototypeOf(ViewModel, WelcomeVM, {
    intent: { //swipe or override
        GO_HOME: ({ context }) => {
            context.navigate("home");
        },
        GO_INSERT: ({ context }) => {
            context.navigate("insert");
        },
        GO_SUBSCRIBE: ({ context }) => {
            context.navigate("subscribe");
        },
        GO_UNSUBSCRIBE: ({ context }) => {
            context.navigate("unsubscribe");
        },
        GO_ADMIN: ({ context }) => {
            context.navigate("settings");
        },
        GO_INVITEIN: ({ context }) => {
            context.navigate("invite");
        },
    }
});