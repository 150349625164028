import { useApp } from "@essenza/react";
import { Button, Layout, Menu } from "antd";
import { HomeOutlined, MenuOutlined, AppleOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import SubMenu from "antd/lib/menu/SubMenu";
import { Logo } from "./logo";
import { ReactComponent as AllUsers } from "../assets/icon/allUsers.svg";
import { ReactComponent as SubscribeIcon } from "../assets/icon/subscribeIcon.svg";
import { ReactComponent as UnsubscribeIcon } from "../assets/icon/unsubscribeIcon.svg";
import { ReactComponent as Logout } from '../assets/icon/logout.svg';

const { Sider } = Layout;

export function AppSider({ token }) {
  const [collapsed, collapse] = useState(true);
  const app = useApp();
  // if(!app.logged) return null;
  return (
    <Sider className="bg-grey px-[32px]">
      <div className="flex flex-col justify-between	h-screen">
        <div className="pt-[32px] flex flex-col gap-y-[16px]">
          <Logo className="w-[165px] mb-[8px]" />
          {app.logged &&
            <>
              <Button onClick={() => app.navigate("home")} className="bg-blue hover:bg-copy text-white rounded-xs px-32px py-16px border-0 shadow-none flex flex-row gap-x-[16px] items-center">{<AllUsers className="fill-white" />}Elenco Utenti</Button>
            </>
          }
        </div>
        {app.logged &&
          <div className="pb-[32px] flex flex-col gap-y-[16px]">
            <Button className="mx-auto block" onClick={() => app.navigate("settings")}>GO ADMIN</Button>
            <Button className="mx-auto block" onClick={() => app.navigate("invite")}>GO INVITEIN</Button>
            <Button onClick={() => app.navigate("unsubscribe")} className="flex flex-row gap-x-[16px] items-center justify-items-start p-0 bg-transparent hover:bg-transparent text-copy hover:text-copy border-0 shadow-none sidebarButton">{<SubscribeIcon className="fill-copy" />}Link disiscrizione</Button>
            <Button onClick={() => app.navigate("subscribe")} className="flex flex-row gap-x-[16px] items-center justify-items-start p-0 bg-transparent hover:bg-transparent text-copy hover:text-copy border-0 shadow-none sidebarButton">{<UnsubscribeIcon className="fill-copy" />}Link iscrizione</Button>
            <Button onClick={() => window.location.replace(window.location.origin)} className="flex flex-row gap-x-[16px] items-center justify-items-start p-0 bg-transparent hover:bg-transparent text-blue hover:text-copy border-0 shadow-none sidebarButton underline">{<Logout className="fill-copy h-[25px] w-auto" />}Logout</Button>
          </div>
        }
      </div>
    </Sider>
  );
}