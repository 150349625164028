import React, { useEffect } from 'react';
import { useWidget, UserVM, Widget, useForm, Form, FormItem } from "@essenza/react";

import { Button, Input } from 'antd';

export function Login({ user, rules }) {
    const vm = useWidget(UserVM);
    const form = useForm(user, { rules });

    console.log("LOGIN RENDER");

    useEffect(() => {
        let instance = form.target.getFieldInstance("email");
        instance.focus();
    }, []);

    return (
        <Widget className="flex flex-col">
            <Form form={form} style={{ marginTop: '24px' }} layout='vertical' className="layout-form bg-white w-full xbk:w-[450px] p-[32px] rounded ">
                <FormItem label="Email" name="email" className="custom-label">
                    <Input placeholder="mariorossi@email.it" className='rounded-xxs border-medium-grey hover:border-blue focus:border-blue shadow-none text-copy px-16px py-12px'></Input>
                </FormItem>
                <FormItem label="Inserisci Password" name="password" className="custom-label">
                    <Input.Password placeholder="********" className='rounded-xxs border-medium-grey hover:border-blue focus:border-blue shadow-none text-copy px-16px py-12px'></Input.Password>
                </FormItem>
                <FormItem className="text-center">
                    <Button className='bg-blue hover:bg-copy text-white rounded-xs px-32px py-16px border-0 shadow-none' onClick={() => vm.emit("LOGIN")}>
                        Login
                    </Button>
                </FormItem>
            </Form>
        </Widget>
    )
}