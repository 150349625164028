export function LoadingAtomicIcon() {
    return (
        <>
            <div className="r1">
                <div></div>
            </div>
            <div className="r2">
                <div></div>
            </div>
        </>
    );
}