import { useWidget, Widget, core, ViewModel, useForm, Form, FormItem, useModel, useValue, RULES, useBreakPoint, useApp } from "@essenza/react";
import { Button, Checkbox, Input, Select, notification } from 'antd';
import { GoogleGroupsModel } from "../data/googleGroupsModel";
import * as yup from 'yup';
import { GoogleMemberModel } from "../data/googleMemberModel";
import { ReactComponent as CopyClipboardIcon } from "../assets/icon/copyClipboardIcon.svg";
import { ReactComponent as AllUsers } from "../assets/icon/allUsers.svg";
import { ReactComponent as SubscribeIcon } from "../assets/icon/subscribeIcon.svg";
import { ReactComponent as UnsubscribeIcon } from "../assets/icon/unsubscribeIcon.svg";
import { ReactComponent as Logout } from '../assets/icon/logout.svg';
import { ReactComponent as AddUser } from "../assets/icon/addUser.svg";

export function Subscribe() {
    const vm = useWidget(SubscribeVM);
    const groups = useValue("groups");
    const [member] = useModel(GoogleMemberModel);
    const form = useForm(member.newInstance(), { rules: vm.rules });
    const breakpoint = useBreakPoint('md');
    const app = useApp();

    return (
        <Widget>
            <div className="w-full flex pt-32px px-[32px] bk:px-[48px]">
                <div className="w-[1268px] flex flex-col items-center gap-y-8 text-copy">
                    <div className="w-3/4 2xl:w-1/2 text-center flex flex-col gap-y-[16px]">
                        <h1>Ricevi i comunicati dell'agenzia Galli Torrini</h1>
                        <p>Non perderti neanche un aggiornamento iscrivendoti gratuitamente a una o più delle nostre mailing list</p>
                    </div>
                    <div className="bg-white bk:w-2/3 2xl:w-1/2 rounded py-10 text-center flex flex-col items-center">
                        <div className="w-4/5 bk:w-2/3 flex flex-col gap-y-6">
                            <h2>Compila il form per iscriverti</h2>
                            <Form form={form} className="flex flex-col items-start custom-label" layout="vertical">
                                <FormItem label="Email" name="email" className="w-full">
                                    <Input className="rounded-xxs border-medium-grey hover:border-blue focus:border-blue shadow-none text-copy px-16px py-12px" placeholder="Es. mariorossi@email.it"></Input>
                                </FormItem>
                                <FormItem label="A quali mailing list vuoi iscriverti?" name="mailinglist" className="w-full">
                                    <Select mode="multiple" placeholder="Seleziona" className="rounded-xxs border-medium-grey hover:border-blue focus:border-blue shadow-none text-copy text-left" options={groups} fieldNames={{ label: "name", value: "email" }} />
                                </FormItem>
                                <FormItem>
                                    <Checkbox className="text-left">
                                        <p className="text-copy text-[13px]">Acconsento al trattamento dei miei dati personali come specificato all'interno della <a className="underline hover:underline hover:text-blue" href="https://www.gallitorrini.com/privacy-policy/" target="_blank">privacy policy</a></p>
                                    </Checkbox>
                                </FormItem>
                                <FormItem>
                                    <Button className="bg-blue hover:bg-copy text-white rounded-xs px-32px py-16px border-0 shadow-none" onClick={e => vm.emit("SUBMIT")}>Iscriviti</Button>
                                </FormItem>
                            </Form>
                        </div>
                    </div>
                    <p className="font-bold mb-[-24px] text-center">Clicca sul pulsante! Copia il link e condividelo a chiunque voglia iscriversi!</p>
                    <Button className="flex flex-row gap-x-[16px] items-center bg-blue hover:bg-copy text-white rounded-xs px-32px py-16px border-0 shadow-none" onClick={e => vm.emit("COPY")} >Copia il link negli appunti{<CopyClipboardIcon className="copy-clipboard-icon h-[25px] w-auto" />}</Button>
                    {breakpoint.md.active
                        ?
                        <>
                        </>
                        :
                        <>
                            <div className="flex flex-row justify-center gap-x-[8px] bg-grey bottom-[10px] rounded p-[24px] custom-home-footer">
                                <Button onClick={() => app.navigate("home")} className="p-[10px] bg-trasparent hover:bg-blue active:bg-blue text-white rounded-xs border-0 shadow-none fill-blue hover:fill-white active:fill-white">{<AllUsers className="h-[25px] w-auto" />}</Button>
                                <Button onClick={() => vm.emit("ADD_MEMBER")} className="p-[10px] bg-trasparent hover:bg-blue active:bg-blue text-white rounded-xs border-0 shadow-none fill-blue hover:fill-white active:fill-white"><AddUser className="h-[25px] w-auto" /></Button>
                                <Button onClick={() => app.navigate("unsubscribe")} className="p-[10px] bg-transparent hover:bg-transparent text-copy hover:text-copy border-0 shadow-none sidebarButton fill-copy hover:fill-blue">{<SubscribeIcon className="h-[25px] w-auto" />}</Button>
                                <Button onClick={() => window.location.replace(window.location.origin)} className="p-[10px] bg-transparent hover:bg-transparent text-blue hover:text-copy border-0 shadow-none sidebarButton underline fill-copy hover:fill-blue">{<Logout className="h-[25px] w-auto" />}</Button>
                            </div>
                        </>
                    }
                </div>
            </div>
        </Widget>
    )
}

export function SubscribeVM() {
    ViewModel.call(this);
}

core.prototypeOf(ViewModel, SubscribeVM, {
    get rules() {
        return yup.object({
            email: RULES.email(yup),
        })
    },
    intent: { //swipe or override
        SUBMIT: async function ({ context }) {
            const validation = await this.form.validate(true);
            if (validation.isValid) {
                console.log("MEMBER SUBSCRIPTION FORM IS VALID", validation.data);
                const model = this.$member;
                model.subscribe(validation.data.email, validation.data.mailinglist).then(() => {
                    notification.success({ description: "Utente iscritto con successo." });
                });
            }
        },
        COPY: function () {
            navigator.clipboard.writeText("http://database.gallitorrini.com/?subscribe=*req*");
            notification.success({description: "Link copiato correttamente negli appunti!"});
        },
    }
});