import './App.css';
import { AppRoot, useApp, useBreakPoint } from "@essenza/react";
import { Route, Routes } from 'react-router-dom';
import { ConfigureApp } from './config';

import { MainLayout } from './layout/MainLayout';
import { MobileLayout } from './layout/MobileLayout';

import { WelcomeVista } from './vista/welcome';
import { HomeVista } from './vista/home';
import { SubscribeVista } from './vista/subscribe';
import { UnsubscribeVista } from './vista/unsubscribe';
import { InsertVista } from './vista/insert';
import { Mobile } from './widget/mobile';
import { LoginVista } from './vista/profile/login';
import { UserAdminVista } from './vista/profile/admin';
import { UserVista } from './vista/profile/detail';
import { ProfileVista } from './vista/profile/profile';
import { InviteVista } from './vista/profile/invitein';
import { GoogleGroupsModel } from './data/googleGroupsModel';


const init = app => {

  ConfigureApp(app);

  app.observe("READY").make(() => console.log("APP READY OBSERVED")).once();
  app.observe("AUTH").make(() => app.navigate("login"));
  app.observe("LOGGED").make(() => app.navigate("home"));
  app.observe("URL_REQUEST").make(({data, context}) => {
    if(data.request === "SUBSCRIBE"){
      context.navigate("/subscribe");
    }
    else if(data.request === "UNSUBSCRIBE"){
      context.navigate("/unsubscribe");
    }
  });

  const group = new GoogleGroupsModel();
  app.block.wait(group.all().then(result=>app.setSource("groups", result.data)));
}

function App() {
  const app = useApp(init);
  const breakpoint = useBreakPoint('md');

  return (
    <div className="App">
      <AppRoot>
        <Routes>
          {breakpoint.md.active
            ? <Route path="/" element={<MainLayout />}>
              <Route path="home" element={<HomeVista />} />
              <Route path="subscribe" element={<SubscribeVista />} />
              <Route path="unsubscribe" element={<UnsubscribeVista />} />
              <Route path="insert" element={<InsertVista />} />
              <Route path="settings" element={<UserAdminVista />} />
              <Route path="user-detail" element={<UserVista />} />
              <Route path="profile" element={<ProfileVista />} />
              <Route path="invite" element={<InviteVista />} />
            </Route>
            :
            <Route path="/" element={<MobileLayout />}>
              <Route path="home" element={<HomeVista />} />
              <Route path="subscribe" element={<SubscribeVista />} />
              <Route path="unsubscribe" element={<UnsubscribeVista />} />
            </Route>
          }
          <Route index element={<WelcomeVista />} />
          <Route path="login" element={<LoginVista />} />
        </Routes>
      </AppRoot>
    </div>
  );
}

export default App;