import { useVista, Vista } from "@essenza/react";

import { Unsubscribe } from "../widget/unsubscribe";

export function UnsubscribeVista() {
    const vm = useVista();

    return (
        <Vista>
            <Unsubscribe />
        </Vista>
    )
}