import { DataModel, core } from "@essenza/react";

export function GoogleGroupsModel(){
    DataModel.call(this);
}

core.prototypeOf(DataModel, GoogleGroupsModel, {
    etype: "group",

    all() {
        return this.ExecuteApi("groups").then(result => {
            console.log("Chiamata API Lista Gruppi funzionante!", result);
            return result;
        }).catch(e => ({ status: "NACK", value: e}));
    },

    //Mostra l'elenco di tutti i gruppi a cui appartiene un membro.
    of(email) {
        return this.ExecuteQuery("member_groups", {email:email}).then(result => {
            console.log("Chiamata API lista gruppi di un utente con successo!", result);
            return result;
        }).catch(e => ({ status: "NACK", value: e}));
    },

    subscribe: function (email, groupkey) {
        return this.ExecuteApi("subscribe_to", { email: email, groupkey }).then(result => {
            console.log("Viva la fava del corra!", result);
            return result;
        });
    },

    unsubscribe: function (email, groupkey, remove) {
        return this.ExecuteApi("unsubscribe_from", { email: email, groupkey, remove }).then(result => {
            console.log("Viva la fava del corra!", result);
            return result;
        });
    },
});